import React from 'react';
import ReactDOM from 'react-dom';

import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom"

import 'assets/css/index.css';
import Home from 'views/Home/Home';
import * as serviceWorker from './serviceWorker';

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/"><Home /></Route>
    </Switch>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
