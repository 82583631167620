import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import LOGO from 'assets/images/courtHive-white-logo-only.png';

import Button from 'components/Buttons/Button';

import styles from 'assets/jss/headerLinks.js';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="tmx-tooltiop"
          title="Tournament Management"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://CourtHive.com/tmx"
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
             TMX
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="live-tooltiop"
          title="Tournament Results"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://CourtHive.com/live"
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
             Live
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="github-tooltip"
          title="Fork us on GitHub"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://www.github.com/courthive"
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
             GitHub
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
      </ListItem>
    </List>
  );
}

export function LeftHeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <img style={{height: '30px'}} src={LOGO} alt="LOGO" />
      </ListItem>
    </List>
  );
}