import React from 'react';
import './Home.css';

import Header from 'components/Header/Header';
import Footer from "components/Footer/Footer";
import HeaderLinks, { LeftHeaderLinks } from 'components/Header/HeaderLinks';
import Parallax from 'components/Parallax/Parallax';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import TENNISBALL from 'assets/images/tennis-ball-optimized.jpg';
import TODS from 'assets/images/TODS.png';

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/components.js";
import sectionStyles from "assets/jss/centeredParagraph.js";
const useStyles = makeStyles(styles);
const useSectionStyles = makeStyles(sectionStyles);

function Home(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="site">
      <Header
        fixed
        leftLinks={<LeftHeaderLinks />}
        rightLinks={<HeaderLinks />}
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax image={TENNISBALL}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>CourtHive</h1>
                <h3 className={classes.subtitle}>
                  Open Source Tennis
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionCompletedExamples />
      </div>
      <Footer />
    </div>
  );
}

function SectionCompletedExamples() {
  const classes = useSectionStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>About</h2>
            <h4 style={{fontWeight: 1}}>
              CourtHive is an Open Source / Open Data initiative to develop applications for Tennis which are affordable (free), 
              accessible (cross-platform with support for 30+ languages) and which promote the emergence of standards for the 
              representation of data related to Tennis.
            </h4>
            <h4 style={{fontWeight: 1}}>
              CourtHive applications enable Live Capture and Broadcast of Tennis Match Data. 
              CourtHive TMX is a Tournament Management platform which saves tournament organizers time, enables referees to leave the office and “go mobile”, enhances the experience of players, families and fans with live publishing of scores and schedules, while increasing the visibility of sponsors.
            </h4>
            <h4>
              <a href='https://itftennis.atlassian.net/wiki/spaces/TODS/overview' target='_blank' rel='noopener noreferrer'>
                <img style={{height: '100px'}} src={TODS} alt="TODS" />
              </a>
            </h4>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default Home;
